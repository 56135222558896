import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutGuard } from './core/auth/logout.guard';
import { AuthGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './content/auth/login/login.module#LoginModule',
    canActivate:[LogoutGuard]
  },
  {
    path: 'forget-password',
    loadChildren: './content/auth/forget-password/forget-password.module#ForgetPasswordModule',
    canActivate:[LogoutGuard]
  },
  {
    path: 'forget-password-confirmation',
    loadChildren: './content/auth/forget-password-confirmation/forget-password-confirmation.module#ForgetPasswordConfirmationModule',
    canActivate:[LogoutGuard]
  } ,
  {
    path: 'privacy_policy',
    loadChildren: './content/auth/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
  } ,
  {
    path: 'legal_policy',
    loadChildren: './content/auth/legal-policy/legal-policy.module#LegalPolicyModule',
  } ,
  {
    path: 'pages',
    loadChildren: './content/pages/pages.module#PagesModule',
    canActivate:[AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
